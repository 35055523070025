import { memo, useCallback, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, Col, UncontrolledTooltip } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import PipelineCard from '../PipelineCard';

const size = 10

const PipelineCol = ({workflow_step, viewing_user, hideActions, hideLastNote, sortOptions, user}) => {
   
    const listInnerRef = useRef();
    const [doneFetching, setDoneFetching] = useState(null)
    const [matters, setMatters] = useState(null)
    const [totalDocuments, setTotalDocuments] = useState(null)
    const [sortType, setSortType] = useState('newest')

    const fetchMore = useCallback(async () => {
        if(doneFetching) return

        const sort = JSON.parse(JSON.stringify(sortOptions))

        if(sortType === 'oldest') {
            if(sort['incoming_communication_at'] === -1) sort['incoming_communication_at'] = 1
            if(sort['current_step_start'] === -1) sort['incoming_communication_at'] = 1
            if(sort['created_at'] === -1) sort['created_at'] = 1
            if(sort['_id'] === -1) sort['_id'] = 1
        }
        
        const params = { 
            searchString: '', 
            skip: matters ? matters.length : 0,  
            limit:  size, 
            sort,
            filter: { 
                'roles.user': user ? user : viewing_user._id,
                workflow_step
            } 
        }
       
        const query = await api.matters.search(params)
        if(query.data) {
            let _matters = matters ? matters.concat(query.data.documents) : query.data.documents
            if(query.data.total_documents === _matters.length) setDoneFetching(true)
            setMatters(_matters)
        }

    }, [doneFetching, sortOptions, sortType, matters, user, viewing_user._id, workflow_step])

    const fetchInitial = useCallback(async () => {

        const sort = JSON.parse(JSON.stringify(sortOptions))

        if(sortType === 'oldest') {
            if(sort['incoming_communication_at'] === -1) sort['incoming_communication_at'] = 1
            if(sort['current_step_start'] === -1) sort['incoming_communication_at'] = 1
            if(sort['created_at'] === -1) sort['created_at'] = 1
            if(sort['_id'] === -1) sort['_id'] = 1
        }

        const params = { 
            searchString: '', 
            skip: 0,  
            limit:  size,
            sort, 
            filter: { 
                'roles.user': user ? user : viewing_user._id,
                workflow_step
            } 
        }
       
        const query = await api.matters.search(params)
        if(query.data) {
            setDoneFetching(query.data.total_documents === query.data.documents.length ? true : false)
            setMatters(query.data.documents)
            setTotalDocuments(query.data.total_documents)
        }

    }, [sortOptions, sortType, user, viewing_user._id, workflow_step])

    const onScroll = useCallback(() => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollHeight < scrollTop + clientHeight + 1) {
                fetchMore()
            }
        }
    }, [fetchMore])

    useEffect(() => {
        fetchInitial()
    }, [fetchInitial])

    useEffect(() => {
        setDoneFetching(null)
        setMatters(null)
        setTotalDocuments(null)
    }, [user, fetchInitial])

    return (
        <Col className="archk-pipeline-col">

            <div className="archk-pipeline-outer z-depth-2">
            <div className="bg-success  px-4 py-2">
                <h3 className="text-white pt-1">
                    <span id={`archk-pipeline-col-${workflow_step}`}>
                        <ObjectFinder 
                            trim={22} 
                            collection="workflow_steps" 
                            _id={workflow_step}  
                        /> 
                    </span>

                    <i 
                        onClick={() => setSortType(sortType === 'newest' ? 'oldest' : 'newest')} 
                        className={`fas cursor-pointer fa-sort-amount-${sortType === 'newest' ? 'down' : 'up'} float-right pt-2 pl-2`} 
                    />

                    <span className="float-right">{totalDocuments !== null ? `(${totalDocuments}) ` : ' '}</span>

                    <UncontrolledTooltip delay={0} placement="bottom" target={`archk-pipeline-col-${workflow_step}`}>
                        <ObjectFinder collection="workflow_steps" _id={workflow_step}  /> 
                    </UncontrolledTooltip>

                </h3>
            </div>

            <div 
                className="archk-pipeline-col-wrapper border-left border-right"  
                onScroll={onScroll} 
                ref={listInnerRef}
            >
                <div className="archk-pipeline-col-inner pt-3">

                    {!matters ? (
                        <Circle />
                    ) : !matters.length ? (
                        <Card>
                            <CardBody>
                                <p className="mb-0 text-sm">
                                    <i className="fas fa-info-circle mr-2 text-info" /> No Matters Found
                                </p>
                            </CardBody>
                        </Card>
                    ) : matters.map(matter => (
                        <PipelineCard  
                            key={matter._id}
                            hideActions={hideActions}
                            hideLastNote={hideLastNote}
                            matter={matter} 
                        />
                    ))}

                    {!doneFetching ? (
                        <button onClick={fetchMore} className="btn btn-block btn-success my-3">Fetch More</button>
                    ) : ''}

                </div>
            </div>
            </div>
        </Col>
    )
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(PipelineCol));
