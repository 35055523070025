import r from './request'

const api = {

    auth: {
        mfa: {
            send: (d)  => r('SERVER', 'get', `/v1/auth/mfa?_id=${d._id}&company=${d.company}&type=${d.type}`),   
            set: (d)   => r('SERVER', 'post', `/v1/auth/mfa/set`, d),   
        },
        password: {
            update: (d)  => r('SERVER', 'post', `/v1/auth/password/update`, d),
            reset:  (d)  => r('SERVER', 'post', `/v1/auth/password/reset`, d),
        },
        sso: {
            fetch:   ()   => r('SERVER', 'get', `/v1/auth/sso`),
            signOn:  (d)  => r('SERVER', 'post', `/v1/auth/sso`, d),
        },
        viewing_user: ()    => r('SERVER', 'get', `/v1/auth/viewing_user`),
    },

    ai_problems: {
        search    : (d)     => r('SERVER', 'post',  `/v1/ai_problems/search`, d),
    },

    automations: {
        create    : (d)     => r('SERVER', 'post',  `/v1/automations`, d),
        search    : (d)     => r('SERVER', 'post',  `/v1/automations/search`, d),
        findById  : (i)     => r('SERVER', 'get',  `/v1/automations/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/automations/${i}`, d),
        delete    : (i)     => r('SERVER', 'delete', `/v1/automations/${i}`),
    },
    
    booking_links: {
        search : (d)    => r('SERVER', 'post',   `/v1/booking_links/search`, d),
        find : (w)      => r('SERVER', 'get',    `/v1/booking_links?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/booking_links/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/booking_links/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/booking_links/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/booking_links/${i}`),
    },

    bookmarks: {
        create    : (d)     => r('SERVER', 'post',  `/v1/bookmarks`, d),
        find      : ()      => r('SERVER', 'get',  `/v1/bookmarks`),
        delete    : (i)     => r('SERVER', 'delete', `/v1/bookmarks/${i}`),
    },

    automation_tasks: {
        create    : (d)     => r('SERVER', 'post',  `/v1/automation_tasks`, d),
        find      : (i)     => r('SERVER', 'get',  `/v1/automation_tasks/${i}`),
        delete    : (i, d)  => r('SERVER', 'delete', `/v1/automation_tasks/${i}`, d),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/automation_tasks/${i}`, d),
    },

    call_phone_numbers: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_phone_numbers/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_phone_numbers`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_phone_numbers/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_phone_numbers/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_phone_numbers/${i}`),
    },

    call_flows: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_flows/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/call_flows`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_flows/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_flows/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/call_flows/${i}`),
    },
    call_queue_entries: {
        check : ()      =>  r('SERVER', 'get',  `/v1/call_queue_entries/check`),
        delete : (i)      =>  r('SERVER', 'delete',  `/v1/call_queue_entries/${i}`),
    },
    call_offers: {
        decline : (i)      =>  r('SERVER', 'patch',  `/v1/call_offers/${i}`),
        findByUser : (i, s, e)      =>  r('SERVER', 'patch',  `/v1/call_offers/${i}/user?start=${s}&end=${e}`),
    },
   
    call_voicemails: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/call_voicemails/${i}`),
        search   : (d)      =>  r('SERVER', 'post', `/v1/call_voicemails/search`, d),
        resolve   : (i, d)   =>  r('SERVER', 'patch',  `/v1/call_voicemails/${i}/resolve`, d),
    },
    
    calls: {
        recent : (d) =>  r('SERVER', 'post',  `/v1/calls/recent`, d),
        search : (d) =>  r('SERVER', 'post',  `/v1/calls/search`, d),
        findById : (i) =>  r('SERVER', 'get',  `/v1/calls/${i}`),
        setReturned : (i) =>  r('SERVER', 'post',  `/v1/calls/${i}`),
    },
    call_center: {
        accessToken : () =>  r('SERVER', 'get',  `/v1/call_center/access_token`),
        matterInfo : (i) =>  r('SERVER', 'get',  `/v1/call_center/${i}/matter`),
        contactInfo : (i) =>  r('SERVER', 'get',  `/v1/call_center/${i}/contact`),
        addCall : (d)      =>  r('SERVER', 'post',  `/v1/call_center/add_call`, d),
        mergeCall : (d)      =>  r('SERVER', 'post',  `/v1/call_center/merge_call`, d),
        cancelTransfer : (d)      =>  r('SERVER', 'post',  `/v1/call_center/cancel_transfer`, d),
        hangup : (d)      =>  r('SERVER', 'post',  `/v1/call_center/hangup`, d),
        hold : (d)      =>  r('SERVER', 'post',  `/v1/call_center/hold`, d),
    },


    companies: {
        findByName    : (name)  =>  r('SERVER', 'get', `/v1/companies/name/${name}`),
        findById      : (i)     =>  r('SERVER', 'get', `/v1/companies/${i}`),
        update        : (i, d)  =>  r('SERVER', 'patch', `/v1/companies/${i}`, d),
    },

    contact_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/contact_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/contact_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/contact_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/contact_types/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/contact_types/${i}`),
    },

    contacts: {
        search                    : (d)         =>  r('SERVER', 'post',  `/v1/contacts/search`, d),
        find                      : (query)     =>  r('SERVER', 'get',   `/v1/contacts${query}`),
        findById                  : (i)         =>  r('SERVER', 'get',   `/v1/contacts/${i}`),
        findByPhone               : (p)         =>  r('SERVER', 'get',   `/v1/contacts/${p}/phone`),
        overview                  : (i)         =>  r('SERVER', 'get',   `/v1/contacts/${i}/overview`),
        create                    : (d)         =>  r('SERVER', 'post',  `/v1/contacts`, d),
        update                    : (i, d)      =>  r('SERVER', 'patch', `/v1/contacts/${i}`, d),
        delete                    : (i)         =>  r('SERVER', 'delete', `/v1/contacts/${i}`,),
    },

    custom_fields: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/custom_fields/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/custom_fields`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/custom_fields/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/custom_fields/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/custom_fields/${i}`),
        find     : (d)      =>  r('SERVER', 'get',  `/v1/custom_fields/${d}/division`),
    },
    
    custom_views: {
        find     : (d)      =>  r('SERVER', 'get',  `/v1/custom_views`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/custom_views/${i}`),
    },

    dashboard: {
        sidebar      : (d)       =>  r('SERVER', 'get',  `/v1/dashboard/sidebar/${d}`),
        overview     : ()       =>  r('SERVER', 'get',  `/v1/dashboard/overview`),
    },
    
    divisions: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/divisions`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/divisions/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/divisions`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/divisions/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/divisions/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/divisions/search`, d),
    },
    
    division_departments: {
        findUsers : (i)      =>  r('SERVER', 'get',  `/v1/division_departments/${i}/users`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/division_departments/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/division_departments`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/division_departments/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/division_departments/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/division_departments/search`, d),
        userAdd   : (i, d)  =>  r('SERVER', 'post',  `/v1/division_departments/${i}/user`, d),
        userRemove   : (i, d) =>  r('SERVER', 'delete',  `/v1/division_departments/${i}/user`, d),
    },

    documents: {
        search    : (d) =>  r('SERVER', 'post',  `/v1/documents/search`, d),
        byContact : (i) =>  r('SERVER', 'get',  `/v1/documents/contact/${i}`),
        findById  : (i) => r('SERVER', 'get', `/v1/documents/${i}`),
        delete    : (i) => r('SERVER', 'delete', `/v1/documents/${i}`),
        bulkDelete    : (d) => r('SERVER', 'delete', `/v1/documents/bulk`, d),
    },

    email_senders: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/email_senders`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/email_senders/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/email_senders`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/email_senders/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/email_senders/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/email_senders/${i}`),                                                  
    },

    events: {
        find            : ()               =>  r('SERVER', 'get',  `/v1/events`),
        findByMatter    : (i)              =>  r('SERVER', 'get',  `/v1/events/${i}/matter`),
        findById        : (i)              =>  r('SERVER', 'get',  `/v1/events/${i}`),
        create          : (d)              =>  r('SERVER', 'post', `/v1/events`, d),
        search          : (d)              =>  r('SERVER', 'post', `/v1/events/search`, d),
        update          : (i, d)           =>  r('SERVER', 'patch',  `/v1/events/${i}`, d),
        delete          : (i)              =>  r('SERVER', 'delete',  `/v1/events/${i}`),
        finish          : (i, d)           =>  r('SERVER', 'patch',  `/v1/events/${i}/finish`, d),
        calendar        : (start, end)     =>  r('SERVER', 'get',  `/v1/events/calendar?start=${start}&end=${end}`),
    },
    calendar_events: {
        calendar        : (start, end, user)     =>  r('SERVER', 'get',  `/v1/calendar_events?start=${start}&end=${end}&user=${user}`),
    },

    email_templates: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/email_templates`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/email_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/email_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/email_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/email_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/email_templates/${i}`),                                              
    },

    event_templates: {
        find     : ()       =>  r('SERVER','get',  `/v1/event_templates`),
        findById : (i)      =>  r('SERVER','get',  `/v1/event_templates/${i}`),
        create   : (d)      =>  r('SERVER','post', `/v1/event_templates`, d),
        search   : (d)      =>  r('SERVER','post', `/v1/event_templates/search`, d),
        update   : (i, d)   =>  r('SERVER','patch',  `/v1/event_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER','delete',  `/v1/event_templates/${i}`),                                       
    },

    event_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/event_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/event_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/event_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/event_types/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/event_types/${i}`),
        search  : (d)           => r('SERVER', 'post',   `/v1/event_types/search`, d),
    },


    emails: {
        compose              : (d) => r('SERVER', 'post',   `/v1/emails/compose`, d),
        send              : (d) => r('SERVER', 'post',   `/v1/emails`, d),
        getThread         : (i) => r('SERVER', 'get',   `/v1/emails/${i}/thread`),
        getAttachments    : (i) => r('SERVER', 'get',   `/v1/emails/${i}/attachments`),
    },

    email_threads: {
        update            : (i, d) => r('SERVER', 'patch',   `/v1/email_threads/${i}`, d),
        search            : (d) => r('SERVER', 'post',   `/v1/email_threads/search`, d),
        read              : (i, d) => r('SERVER', 'patch',   `/v1/email_threads/${i}`, d),
        findById          : (i) => r('SERVER', 'get',   `/v1/email_threads/${i}`),
        findByDivision    : (c, d) => r('SERVER', 'get',   `/v1/email_threads/${c}/${d}`),
    },
  
    form_answers: {
        // company_id, form_id, contact_id
        findByMatter    : (m)    => r('SERVER', 'get',   `/v1/form_answers/${m}`),
        find    : (f, m, c)    => r('SERVER', 'get',   `/v1/form_answers/${f}/${m}/${c}`),
        save    : (d)           => r('SERVER', 'post',   `/v1/form_answers/save`, d),
        search  : (d)           => r('SERVER', 'post',   `/v1/form_answers/search`, d),
    },

    form_submissions: {
        findById        : (i)   => r('SERVER', 'get',   `/v1/api/form_submissions/${i}`),
        findByContact   : (i)   => r('SERVER', 'get',   `/v1/api/form_submissions/contact/${i}`),
        search          : (d)   => r('SERVER', 'post',   `/v1/api/form_submissions/search`, d),
    },
    
    forms: {
        find                    : (d)       => r('SERVER', 'get',   `/v1/forms?division=${d}`),
        create                  : (d)       => r('SERVER', 'post',   `/v1/forms`, d),
        update                  : (i, d)    => r('SERVER', 'patch',  `/v1/forms/${i}`, d),
        delete                  : (i)       => r('SERVER', 'delete', `/v1/forms/${i}`),
        search                  : (d)       => r('SERVER', 'post',   `/v1/forms/search`, d),
        findById                : (i)       => r('SERVER', 'get',    `/v1/forms/${i}`),
        viewDocument            : (Key)     => r('SERVER', 'get',    `/v1/forms/documents/key?Key=${Key}`),
        getConfig               : ()        => r('SERVER', 'get',    `/v1/forms/configuration`),
        request                 : (d)       => r('SERVER', 'post',    `/v1/forms/request`, d),
    },
   
    inject_values: {
        string    : (d)    => r('SERVER', 'post',   `/v1/inject_values/string`, d),
    },
   
    invoices: {
        create          : (d)    => r('SERVER', 'post',   `/v1/invoices`, d),
        update          : (i, d) => r('SERVER', 'patch',   `/v1/invoices/${i}`, d),
        findById        : (i)    => r('SERVER', 'get',   `/v1/invoices/${i}`),
        findByMatter    : (i)    => r('SERVER', 'get',   `/v1/invoices/${i}/matter`),
    },

    load: {
        standard    : ()    => r('SERVER', 'get',   `/v1/load/standard`),
        config      : ()    => r('SERVER', 'get',   `/v1/load/config`),
    },

    locations: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/locations`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/locations/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/locations`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/locations/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/locations/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/locations/${i}`),
    },
  
    location_types: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/location_types`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/location_types/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/location_types`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/location_types/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/location_types/${i}`),
        search   : (d)      =>  r('SERVER', 'post',  `/v1/location_types/search`, d),
    },

    matter_documents: {
        find     : (d)       =>  r('SERVER', 'get',  `/v1/matter_documents?division=${d}`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/matter_documents/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/matter_documents`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/matter_documents/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/matter_documents/${i}`),
        search   : (d)      => r('SERVER', 'post',  `/v1/matter_documents/search`, d),
    },

    matter_document_uploads: {
        find     : (m)      =>  r('SERVER', 'get',  `/v1/matter_document_uploads?matter=${m}`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/matter_document_uploads/${i}`),
        push     : (i, d)   =>  r('SERVER', 'post', `/v1/matter_document_uploads/${i}/${d}`, d),
        pull     : (i, d)   =>  r('SERVER', 'delete', `/v1/matter_document_uploads/${i}/${d}`, d),
        search   : (d)      => r('SERVER', 'post',  `/v1/matter_document_uploads/search`, d),
        update   : (i, d)   => r('SERVER', 'patch',  `/v1/matter_document_uploads/${i}`, d),
        create   : (d)      => r('SERVER', 'post',  `/v1/matter_document_uploads`, d),
    },
    
    matters: {
        search            : (d)     =>  r('SERVER', 'post',  `/v1/matters/search`, d),
        findById          : (i, p)  =>  r('SERVER', 'get',   `/v1/matters/${i}${p ? '?populate=' + p : ''}`),
        billing           : (i)     =>  r('SERVER', 'get',   `/v1/matters/${i}/billing`),
        related           : (i)     =>  r('SERVER', 'get',   `/v1/matters/${i}/related`),
        overview          : (i)     =>  r('SERVER', 'get',   `/v1/matters/${i}/overview`),
        step              : (i)     =>  r('SERVER', 'get',   `/v1/matters/${i}/step`),
        create            : (d)     =>  r('SERVER', 'post',  `/v1/matters`, d),
        update            : (i, d)  =>  r('SERVER', 'patch', `/v1/matters/${i}`, d),
        delete            : (i)     =>  r('SERVER', 'delete', `/v1/matters/${i}`,),
        roleAdd           : (d)     =>  r('SERVER', 'patch', `/v1/matters/roles`, d),
        roleRemove        : (d)     =>  r('SERVER', 'delete', `/v1/matters/roles`, d),
        partyAdd          : (d)     =>  r('SERVER', 'patch', `/v1/matters/parties`, d),
        partyRemove       : (d)     =>  r('SERVER', 'delete', `/v1/matters/parties`, d),
        tagAdd            : (d)     =>  r('SERVER', 'patch', `/v1/matters/tags`, d),
        tagRemove         : (d)     =>  r('SERVER', 'delete', `/v1/matters/tags`, d),
        locationAdd       : (d)     =>  r('SERVER', 'patch', `/v1/matters/locations`, d),
        locationRemove    : (d)     =>  r('SERVER', 'delete', `/v1/matters/locations`, d),
        linkAdd           : (d)     =>  r('SERVER', 'patch', `/v1/matters/links`, d),
        linkRemove        : (d)     =>  r('SERVER', 'delete', `/v1/matters/links`, d),
        bookmarkAdd       : (d)     =>  r('SERVER', 'patch', `/v1/matters/bookmarks`, d),
        bookmarkRemove    : (d)     =>  r('SERVER', 'delete', `/v1/matters/bookmarks`, d),
        alertAdd          : (d)     =>  r('SERVER', 'post', `/v1/matters/alerts`, d),
        alertResolve      : (d)     =>  r('SERVER', 'patch', `/v1/matters/alerts`, d),
        alertsRead        : (d)     =>  r('SERVER', 'patch', `/v1/matters/alerts_read`, d),
        moveStep          : (i, d)  =>  r('SERVER', 'patch', `/v1/matters/${i}/step`, d),
    },
    
    matter_progress_logs: {
        find     : (q)    => r('SERVER', 'get',   `/v1/matter_progress_logs${q}`),
    },
    
    nylas: {
        login : (q)    => r('SERVER', 'get',   `/v1/nylas/login`),
    },
    
    logs_network_users: {
        create : (d) => r('SERVER', 'post', `/v1/logs_network_users`, d),
    },

    notifications: {
        count   : (d)  => r('SERVER', 'get',  `/v1/notifications`),
        
        search   : (d)  => r('SERVER', 'post',  `/v1/notifications/search`, d),
        update   : (i, d)  => r('SERVER', 'patch',  `/v1/notifications/${i}`, d),
    },
    
    notes: {
        find     : (q)    => r('SERVER', 'get',   `/v1/notes${q}`),
        create   : (d)    => r('SERVER', 'post',   `/v1/notes`, d),
        update   : (i, d) => r('SERVER', 'post',   `/v1/notes/${i}`, d),
        delete   : (i)    => r('SERVER', 'post',   `/v1/notes/${i}`),
    },
   
    nylas_email_grants: {
        find     : (q)    => r('SERVER', 'get',   `/v1/nylas_email_grants`),
    },
    
    page_histories: {
        find    : ()  => r('SERVER', 'get', `/v1/page_histories/me`),
        create  : (d) => r('SERVER', 'post', `/v1/page_histories`, { pathname: d }),
    },

    pipelines: {
        find          : (q)    => r('SERVER', 'get',   `/v1/pipelines?division=${q}`),
        create        : (d)    => r('SERVER', 'post',   `/v1/pipelines`, d),
        update        : (i, d) => r('SERVER', 'patch',   `/v1/pipelines/${i}`, d),
        findByUser    : (i, d) => r('SERVER', 'get',   `/v1/pipelines/${i}/user`, d),
        delete        : (i)    => r('SERVER', 'post',   `/v1/pipelines/${i}`),
    },
    payment_methods: {
        create          : (d)    => r('SERVER', 'post',   `/v1/payment_methods`, d),
    },
    payment_subscriptions: {
        create          : (d)    => r('SERVER', 'post',   `/v1/payment_subscriptions`, d),
        update          : (i, d) => r('SERVER', 'patch',   `/v1/payment_subscriptions/${i}`, d),
        delete           : (i)    => r('SERVER', 'delete',   `/v1/payment_subscriptions/${i}`),
    },
    payments: {
        charge          : (d)    => r('PROCESSING', 'post',   `/v1/payments`, d),
        refund          : (d)    => r('PROCESSING', 'post',   `/v1/payments/refund`, d),
        download        : (i)    => r('SERVER', 'get',   `/v1/payments/${i}/download`),
    },

    query: {
        findById    : (c, i, p) => r('SERVER', 'get',  `/v1/query/${c}/${i}${p ? '?populate=' + p : ''}`),
        batch       : (d)       => r('SERVER', 'post',  `/v1/query/batch`, d),
    },

    signing_documents: {
        create          : (d)       => r('SERVER', 'post',  `/v1/signing/documents`, d),
        search          : (d)       => r('SERVER', 'post',  `/v1/signing/documents/search`, d),
        view            : (i)       => r('SERVER', 'get',  `/v1/signing/documents/${i}/view`),
        void            : (i, d)    => r('SERVER', 'patch',  `/v1/signing/documents/${i}`, d),
        findByMatter    : (i, d)    => r('SERVER', 'get',  `/v1/signing/documents/${i}/matter`, d),
        findWithEvents  : (i)       => r('SERVER', 'get',  `/v1/signing/documents/${i}/events`),
        update          : (i, d)       => r('SERVER', 'patch',  `/v1/signing/documents/${i}`, d),
    },

    signing_templates: {
        search    : (d)     => r('SERVER', 'post',  `/v1/signing/templates/search`, d),
        findById  : (i)     => r('SERVER', 'get',   `/v1/signing/templates/${i}`),
        delete    : (i)     => r('SERVER', 'delete',   `/v1/signing/templates/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/signing/templates/${i}`, d),
        getConfig : ()      => r('SERVER', 'get', `/v1/signing/templates/configuration`),
    },
    
    social_security_numbers: {
        findByContact  : (i)     => r('SERVER', 'get',   `/v1/social_security_numbers/${i}`),
        save           : (i, d)  => r('SERVER', 'POST', `/v1/social_security_numbers/${i}`, d),
    },

    string_templates: {
        find      : (d)     => r('SERVER', 'get',  `/v1/string_templates`, d),
        findById  : (i)     => r('SERVER', 'get',   `/v1/string_templates/${i}`),
        delete    : (i)     => r('SERVER', 'delete',   `/v1/string_templates/${i}`),
        update    : (i, d)  => r('SERVER', 'patch', `/v1/string_templates/${i}`, d),
        create    : (d)     => r('SERVER', 'post', `/v1/string_templates/`, d),
    },

    support_tickets: {
        create          : (d) => r('SERVER', 'post',   `/v1/support_tickets`, d),
        search          : (d) => r('SERVER', 'post',   `/v1/support_tickets/search`, d),
        resolve         : (i, d) => r('SERVER', 'post',   `/v1/support_tickets/${i}`, d),
        findById        : (i) => r('SERVER', 'get',   `/v1/support_tickets/${i}`),
        assignMatter    : (s, m) => r('SERVER', 'patch',   `/v1/support_tickets/${s}/${m}`),
    },


    tags: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/tags`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/tags/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/tags`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/tags/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/tags/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/tags/${i}`),
    },

    texts: {
        send        : (d) => r('SERVER', 'post',   `/v1/texts`, d),
    },
    
    text_threads: {
        search            : (d) => r('SERVER', 'post',   `/v1/text_threads/search`, d),
        read              : (i, d) => r('SERVER', 'patch',   `/v1/text_threads/${i}`, d),
        findById          : (i) => r('SERVER', 'get',   `/v1/text_threads/${i}`),
        findByDivision    : (c, d) => r('SERVER', 'get',   `/v1/text_threads/${c}/${d}`),
        setAssignedUsers  : (c, d) => r('SERVER', 'patch',   `/v1/text_threads/${c}/assigned_users`, d),
    },

    text_templates: {
        find     : ()       =>  r('SERVER', 'get',  `/v1/text_templates`),
        findById : (i)      =>  r('SERVER', 'get',  `/v1/text_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/text_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/text_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/text_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/text_templates/${i}`),
    },
    
    tasks: {
        findByStep    : (s, m)   =>  r('SERVER', 'get',  `/v1/tasks/by_step/${s}/${m}`),
        findById      : (i)      =>  r('SERVER', 'get',  `/v1/tasks/${i}`),
        create        : (d)      =>  r('SERVER', 'post', `/v1/tasks`, d),
        search        : (d)      =>  r('SERVER', 'post', `/v1/tasks/search`, d),
        update        : (i, d)   =>  r('SERVER', 'patch',  `/v1/tasks/${i}`, d),
        delete        : (i)      =>  r('SERVER', 'delete',  `/v1/tasks/${i}`),
        finish        : (i, d)      =>  r('SERVER', 'post',  `/v1/tasks/${i}/finish`, d),
        recipients    : (i, d)      =>  r('SERVER', 'get',  `/v1/tasks/${i}/recipients`, d),
    },
    
    time_off_requests: {
        findById    : (i)           =>  r('SERVER', 'get',  `/v1/time_off_requests/${i}`),
        me          : (s)           =>  r('SERVER', 'get',  `/v1/time_off_requests/me${s ? `?status=${s}` : ''}`),
        calendar    : (s, e, dd)    =>  r('SERVER', 'get',  `/v1/time_off_requests/calendar?start=${s}&end=${e}&division_department=${dd}`),
        pending     : ()            =>  r('SERVER', 'get', `/v1/time_off_requests/pending`),
        create      : (d)           =>  r('SERVER', 'post', `/v1/time_off_requests`, d),
        search      : (d)           =>  r('SERVER', 'post', `/v1/time_off_requests/search`, d),
        update      : (i, d)        =>  r('SERVER', 'patch',  `/v1/time_off_requests/${i}`, d),
        delete      : (i)           =>  r('SERVER', 'delete',  `/v1/time_off_requests/${i}`),
    },

    task_templates: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/task_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/task_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/task_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/task_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/task_templates/${i}`),
    },

    time_entry_templates: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/time_entry_templates/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/time_entry_templates`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/time_entry_templates/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/time_entry_templates/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/time_entry_templates/${i}`),
    },
  
    time_logs: {
        log       : (d)      =>  r('SERVER', 'post', `/v1/time_logs/log`, d),
        get       : (d)      =>  r('SERVER', 'get', `/v1/time_logs`, d),
        update    : (i, d)   =>  r('SERVER', 'patch', `/v1/time_logs/${i}`, d),
        create    : (d)      =>  r('SERVER', 'post', `/v1/time_logs/`, d),
        delete    : (i)      =>  r('SERVER', 'delete', `/v1/time_logs/${i}`),
    },

    users: {
        search    : (d)         => r('SERVER', 'post',    `/v1/users/search`, d),
        find      : (query)     => r('SERVER', 'get',    `/v1/users${query}`),
        findOne   : (string)    => r('SERVER', 'get',    `/v1/users/find/${string}`),
        findById  : (i)         => r('SERVER', 'get',    `/v1/users/${i}`),
        create    : (d)         => r('SERVER', 'post',   `/v1/users/`, d),
        update    : (i, d)      => r('SERVER', 'patch',  `/v1/users/${i}`, d),
        delete    : (i)         => r('SERVER', 'delete', `/v1/users/${i}`),
        me        : ()          => r('SERVER', 'get', `/v1/users/me`),
    },
    user_activities: {
        create    : (d)         => r('PROCESSING', 'post',    `/v1/user_activities`, d),
    },

    wikis: {
        findById : (i)      =>  r('SERVER', 'get',  `/v1/wikis/${i}`),
        create   : (d)      =>  r('SERVER', 'post', `/v1/wikis`, d),
        search   : (d)      =>  r('SERVER', 'post', `/v1/wikis/search`, d),
        update   : (i, d)   =>  r('SERVER', 'patch',  `/v1/wikis/${i}`, d),
        delete   : (i)      =>  r('SERVER', 'delete',  `/v1/wikis/${i}`),
    
    },
   
    workflows: {
        find : ()       => r('SERVER', 'get',    `/v1/workflows`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflows/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflows/`, d),
        search : (d)    => r('SERVER', 'post',   `/v1/workflows/search`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflows/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflows/${i}`),
    },
   
    workflow_roles: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_roles?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_roles/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_roles/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_roles/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_roles/${i}`),
    },
      
    workflow_folders: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_folders?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_folders/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_folders/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_folders/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_folders/${i}`),
    },
   
    workflow_contacts: {
        find : (w)       => r('SERVER', 'get',    `/v1/workflow_contacts?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_contacts/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_contacts/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_contacts/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_contacts/${i}`),
    },
    
    workflow_links: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_links?workflow=${w}`),
    },

    workflow_locations: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_locations`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_locations/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_locations/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_locations/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_locations/${i}`),
    },
    
    workflow_step_categories: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_step_categories?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_step_categories/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_step_categories/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_step_categories/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_step_categories/${i}`),
    },

    workflow_dispositions: {
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_dispositions?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_dispositions/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_dispositions/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_dispositions/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_dispositions/${i}`),
    },

    workflow_steps: {
        search : (d)    => r('SERVER', 'post',   `/v1/workflow_steps/search`, d),
        find : (w)      => r('SERVER', 'get',    `/v1/workflow_steps?workflow=${w}`),
        findById : (i)  => r('SERVER', 'get',    `/v1/workflow_steps/${i}`),
        create : (d)    => r('SERVER', 'post',   `/v1/workflow_steps/`, d),
        update : (i, d) => r('SERVER', 'patch',  `/v1/workflow_steps/${i}`, d),
        delete : (i)    => r('SERVER', 'delete', `/v1/workflow_steps/${i}`),
    },

    work_center: {
        findNext : (d)    => r('SERVER', 'post',   `/v1/work_center`, d),
    },

    _supervisor: {
        todaysActivity        : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/todays_activity`, d),
        todaysActivityItems   : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/todays_activity/items`, d),
        userActivity          : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/user_activity`, d),
        monthToDate           : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/month_to_date`, d),
        pipelineBreakdown     : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/pipeline_breakdown`, d),
        revenueProjections    : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/revenue_projections`, d),
        mattersOverdue        : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/matters_overdue`, d),
        matterAlerts          : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/matter_alerts`, d),
        phoneCalls            : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/phone_calls`, d),
        supportTickets        : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/support_tickets`, d),
        tasks                 : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/tasks`, d),
        events                : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/events`, d),
        eventCalls            : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/event_calls`, d),
        taskCalls            : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/task_calls`, d),
        matterDocumentUploads : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/matter_document_uploads`, d),
        uploads               : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/uploads`, d),
        leads                 : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/leads`, d),
        callVoicemails        : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/call_voicemails`, d),
        notes                 : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/notes`, d),
        outstandingItems      : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/outstanding_items`, d),
        textThreads           : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/text_threads`, d),
        bookings              : (division, d)       =>  r('SERVER', 'POST',  `/v1/supervisor/${division}/bookings`, d),

        communication: {
            users                 : (i, d)    =>  r('SERVER', 'get',  `/v1/supervisor/${i}/communication/users`, d),
            queues                : (i, start, end)    =>  r('SERVER', 'get',  `/v1/supervisor/${i}/communication/queues?start=${start}&end=${end}`),
            calls                 : (i, d)    =>  r('SERVER', 'post',  `/v1/supervisor/${i}/communication/calls`, d),
            voicemail             : (i, d)    =>  r('SERVER', 'post',  `/v1/supervisor/${i}/communication/voicemail`, d),
            activeQueueEntries    : (i, d)    =>  r('SERVER', 'get',  `/v1/supervisor/${i}/communication/active_queue_entries`, d),
        },

        signatures: {
            analytics   : (i, d)    =>  r('SERVER', 'post',  `/v1/supervisor/${i}/signatures/analytics`, d),
        }

     

        
    },
   
    _ai: {
        outboundSupportTicket : (c, m) =>  r('SERVER', 'get', `/v1/ai/outbound_support_ticket/${c}/${m}`),
    },

    modules: {
        bk: {
            supervisor: {
                docketSearch            : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/docket_search`, data),
                outstandingItemSearch   : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/outstanding_item_search`, data),
                caseSearch              : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/case_search`, data),
                newCaseEntriesSearch    : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/new_case_entries`, data),
                daily                   : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/daily`, data),
                hearings                : (d, data) =>  r('SERVER', 'post', `/v1/modules/bk/supervisor/${d}/hearings`, data),
            }
        }
    }

      
}

export default api;