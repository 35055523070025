import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';


import api from 'api';

import hasPrivilege from 'utils/hasPrivilege'

const Sidebar = ({PLATFORM, toggleSidenav, sidenavOpen, socket, viewing_user, selected_division}) => {

    const location = useLocation(useLocation)

    const items = [
      
     
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TODAYS_ACTIVITY') ? [ {
            active: () => location.pathname === '/',
            name: `Today's Activity`,
            link: '/'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY') ? [ {
            active: () => location.pathname === '/user_activity',
            name: `User Activity`,
            link: '/user_activity'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_ACTIVITY') ? [ {
            active: () => location.pathname === '/pipeline',
            name: `User Pipelines`,
            link: '/pipeline'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MONTH_TO_DATE') ? [ {
            active: () => location.pathname === '/month_to_date',
            name: 'Month To Date',
            link: '/month_to_date'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? [ {
            active: () => location.pathname.includes('/communication'),
            name: 'Communication',
            link: '/communication'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.COMMUNICATION') ? [ {
            active: () => location.pathname.includes('/problems'),
            name: 'Problems',
            link: '/problems'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PIPELINE_BREAKDOWN') ? [ {
            active: () => location.pathname.includes('/pipeline_breakdown'),
            name: 'Pipeline Breakdown',
            link: '/pipeline_breakdown'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.REVENUE_PROJECTIONS') ? [ {
            active: () => location.pathname.includes('revenue_projections'),
            name: 'Revenue Projections',
            link: '/revenue_projections'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MATTERS_OVERDUE') ? [ {
            active: () => location.pathname.includes('matters_overdue'),
            name: 'Matters Overdue',
            link: '/matters_overdue'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.OUTSTANDING_ITEMS') ? [ {
            active: () => location.pathname === '/outstanding_items',
            name: 'Outstanding Items',
            link: '/outstanding_items'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TASKS') ? [ {
            active: () => location.pathname.includes('outstanding_items_calls'),
            name: 'Items Calls',
            link: '/outstanding_items_calls'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.MATTER_ALERTS') ? [ {
            active: () => location.pathname.includes('matter_alerts'),
            name: 'Matter Alerts',
            link: '/matter_alerts'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SIGNATURES') ? [ {
            active: () => location.pathname.includes('signatures'),
            name: 'Signatures',
            link: '/signatures'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.PHONE_CALLS') ? [ {
            active: () => location.pathname === '/calls',
            name: 'Phone Calls',
            link: '/calls'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TEXT_THREADS') ? [ {
            active: () => location.pathname.includes('text_threads'),
            name: 'Text Messages',
            link: '/text_threads'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.SUPPORT_TICKETS') ? [ {
            active: () => location.pathname.includes('support_tickets'),
            name: 'Support Tickets',
            link: '/support_tickets'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.TASKS') ? [ {
            active: () => location.pathname.includes('tasks'),
            name: 'Tasks',
            link: '/tasks'
        }] : []),
     
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? [ {
            active: () => location.pathname.includes('events'),
            name: 'Events',
            link: '/events'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? [ {
            active: () => location.pathname.includes('event_calls'),
            name: 'Event Calls',
            link: '/event_calls'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.EVENTS') ? [ {
            active: () => location.pathname.includes('bookings'),
            name: 'Bookings',
            link: '/bookings'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.DOCUMENTS') ? [ {
            active: () => location.pathname.includes('matter_document') && location.pathname.includes('matter_document_uploads'),
            name: 'Documents',
            link: '/matter_document_uploads'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.VOICEMAIL') ? [ {
            active: () => location.pathname.includes('voicemails'),
            name: 'Voicemails',
            link: '/voicemails'
        }] : []),
        ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.USER_NOTES') ? [ {
            active: () => location.pathname.includes('notes'),
            name: 'User Notes',
            link: '/notes'
        }] : []),       




        // ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.UPLOADS') ? [ {
        //     active: () => location.pathname.includes('/uploads'),
        //     name: 'Uploads',
        //     link: '/uploads'
        // }] : []),
        // ...(hasPrivilege('VIEW.MANAGEMENT_DASHBOARD.LEADS') ? [ {
        //     active: () => location.pathname.includes('/leads'),
        //     name: 'Leads',
        //     link: '/leads'
        // }] : []),
        {
            active: () => location.pathname.includes('/uploads'),
            name: 'Uploads',
            link: '/uploads'
        },
        {
            active: () => location.pathname.includes('/leads'),
            name: 'Leads',
            link: '/leads'
        }
       
    ]
    
    const [counts, setCounts] = useState({
        tasks: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        events: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        documents: {
            pending: 0,
            expired: 0,
        },
        texts: 0,
        emails: 0,
        support_tickets: 0,
        voicemails: 0,
    })
   
    const [sidebar, setSidebar] = useState({
        matters: 'closed',
        contacts: 'closed',
        calls: 'closed',
        pipeline: 'closed',
        tasks: 'closed',
        events: 'closed',
        documents: 'closed',
        texts: 'closed',
        emails: 'closed',
    })

    const onSetSidebar = useCallback((field) => {
        const _sidebar = JSON.parse(JSON.stringify(sidebar));
        _sidebar[field] = _sidebar[field] === 'open' ? 'closed' : 'open'
        setSidebar(_sidebar)
    }, [sidebar])

    const fetchSidebarData = useCallback(async () => {
        const data = await api.dashboard.sidebar(selected_division._id ? selected_division._id : 'all');
        if(data.data) setCounts(data.data)
    }, [selected_division._id])

    const onMessage = useCallback((obj) => {
        if(obj.assigned_to && obj.assigned_to.length && obj.assigned_to.includes(viewing_user._id)) {
            fetchSidebarData()
        } else if(obj.users && obj.users.length && obj.users.includes(viewing_user._id)) {
            fetchSidebarData()
        }
    }, [viewing_user._id, fetchSidebarData])

    useEffect(() => {
        socket.on('TASKS.FINISHED', onMessage)
        socket.on('TASKS.CREATED', onMessage)
        socket.on('TASKS.UPDATED', onMessage)
        socket.on('TASKS.DELETED', onMessage)
        
        socket.on('EVENTS.FINISHED', onMessage)
        socket.on('EVENTS.CREATED', onMessage)
        socket.on('EVENTS.UPDATED', onMessage)
        socket.on('EVENTS.DELETED', onMessage)
        
        socket.on('MATTER_DOCUMENT_UPLOADS.UPDATED', onMessage)
        socket.on('TEXT_THREADS.UPDATED', fetchSidebarData)

        return () => {

            socket.off('TASKS.FINISHED', onMessage)
            socket.off('TASKS.CREATED', onMessage)
            socket.off('TASKS.UPDATED', onMessage)
            socket.off('TASKS.DELETED', onMessage)
            
            socket.off('EVENTS.FINISHED', onMessage)
            socket.off('EVENTS.CREATED', onMessage)
            socket.off('EVENTS.UPDATED', onMessage)
            socket.off('EVENTS.DELETED', onMessage)
            
            socket.off('MATTER_DOCUMENT_UPLOADS.UPDATED', onMessage)
            socket.off('TEXT_THREADS.UPDATED', fetchSidebarData)

        }

    }, [socket, onMessage, fetchSidebarData])


    useEffect(() => {
        fetchSidebarData();
    }, [fetchSidebarData])

    return (
        <>

            {sidenavOpen ? <div onClick={toggleSidenav} className='background-blackout' /> : ''}

            <div className={`archk-sidebar z-depth-1 ${sidenavOpen ? 'open' : ''}`}>


                <div className="py-3 px-4 border-botto text-center">
                    <h2 className='pt-1 text-uppercase'>
                        <div className='text-white' style={{paddingTop: 3}}><img src={PLATFORM.FAVICON} style={{width: 20, position: 'relative', top: -2}} alt="Intuidesk" className="mr-3"/>
                        IntuiDesk </div>
                    </h2>
                </div>

                <div className=' bg-dark border-top  px-4 border-bottom'>
                    <h4 className='text-white py-3 mb-0 text-uppercase'>Actionable Insights</h4>
                </div>

                <div className='border-top'>
                    {items.map((item, i) => {

                        const active = item.active ? item.active(location) ? 'active' : '' : '';
                        const count1 = counts[item.itemField]
                        const textColor = 
                            count1 < 10 ? 'text-info' :
                            count1 <= 49 ? 'text-warning' :
                            'text-danger';

                        const main = (
                            <div 
                                key={i} 
                                className={`py-3 px-4 border-bottom cursor-pointer ${active ? 'active' : ''} `}
                                onClick={() => onSetSidebar(item.field)} 
                            >
                                {/* {console.log(item)} */}
                                <h4 className='mb-0'>
                                    {item.icon && <i style={{display: 'inline-block', width: 20,}} className={item.icon} />}
                                    {item.name} 
                                    {item.itemField && count1 ? <span style={{textShadow: '1px 1px 1px #333', fontSize: 16, marginTop: -2}} className={`float-right font-weight-bold ${textColor}`}>({counts[item.itemField]}) <i className="fas fa-caret-down ml-2 " /></span> : ''}
                                </h4>
                            </div>
                        )

                        return (
                            <div 
                                onClick={() => {
                                    if(sidenavOpen) toggleSidenav();
                                }} 
                                key={i} 
                                className={active ? 'active' : ''}
                            >
                                {item.link ? <Link to={item.link} >{main}</Link> : main }
                                    
                                {item.dropdown && sidebar[item.field] === 'open' && (
                                    <div className="py-3 pr-4 pl-5 border-bottom bg-secondary">
                                        {item.dropdown && item.dropdown.map((d, ii) => {

                                            const count2 = counts[d.itemField]
                                            const color = count2 < 10 ? 'text-info' :
                                            count2 <= 49 ? 'text-warning' :
                                            'text-danger';
                                            return (
                                                <Link to={d.link}>
                                                    <h4 
                                                        className={`py-1 ${d.active ? d.active(location) ? 'active-sub-item' : '' : ''}`} 
                                                        key={ii}
                                                    >
                                                        {d.name} 
                                                        {d.itemField !== undefined && count2 ? (
                                                            <span color={color} style={{minWidth: 40, fontSize: 14}} className={`float-right text-right t `}>
                                                                {counts[d.itemField] > 99 ? '99+' : counts[d.itemField]}
                                                            </span>
                                                        ) : ''}
                                                    </h4>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            
            </div>
        </>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
	    PLATFORM: state.load.PLATFORM,
        matter: state.matters.selected_matter,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
