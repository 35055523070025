import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import { toast } from 'react-toastify';

import formatText from 'utils/formatText';

import moment from 'moment';

const ModalItems = ({DOMAINS, selected_division, showModal, toggleModal, title, filter, collection}) => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const result = await api._supervisor.todaysActivityItems(selected_division._id, { filter, collection })
        if(!result.success) return toast.info(`Could not load items, please try again.`)

        setData(result.data)
    }, [collection, filter, selected_division._id])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setData(null)
        }
    }, [fetchData, showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                {!data ? <Circle /> : collection === 'matters' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Current Step</th>
                                <th>Last Moved</th>
                                <th>Users</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {

                                        const roles = d.roles ? d.roles.filter(u => u.user) : []
                                        const link = `${DOMAINS.APP}/matters/${d.id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td>{d.current_step_name}</td>
                                                <td>{moment.unix(d.current_step_start).format('MM/DD/YYYY : h:mm A')}</td>
                                                <td>
                                                    {roles.map((r, i) => (
                                                        <span key={i}>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={r.user} /></span>
                                                    ))}
                                                </td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'calls' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Status</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `/calls/${d._id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td>{moment.unix(d.start).format('h:mm A')}</td>
                                                <td>{moment.unix(d.end).format('h:mm A')}</td>
                                                <td className="text-capitalize">{d.status ? d.abandon_type === 'short' ? 'Short Abandon' : d.status.replace('_', ' ') : ''}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'bk_credit_reports' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Pulled By</th>
                                <th>Type</th>
                                <th>Time</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td><ObjectFinder collection="users" _id={d.created_by} /></td>
                                                <td>{d.type}</td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'contacts' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Contact</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Created</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/contacts/${d.id}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}><ObjectFinder collection="contacts" _id={d.contact} /></A></td>
                                                <td>{d.email ? d.email : ''}</td>
                                                <td>{d.phone ? formatText(d.phone).phone() : ''}</td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'documents' || collection === 'signing_documents' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Uploaded At</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'events' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Originator</th>
                                <th>Start</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td><A href={link}><ObjectFinder collection="users" _id={d.originator} /></A></td>
                                                <td>{moment.unix(d.unix_start).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : collection === 'outstanding_items' ? (
                    <div className="table-responsive">
                        <table className="table border">
                            <thead>
                                <th>Name</th>
                                <th>Matter</th>
                                <th>Assigned To</th>
                                <th>Due Date</th>
                                <th></th>
                            </thead>

                            <tbody>
                                {data.length ? (
                                    data.map(d => {
                                        const link = `${DOMAINS.APP}/matters/${d.matter}?tab=Items`

                                        return (
                                            <tr key={d._id}>
                                                <td><A href={link}>{d.name}</A></td>
                                                <td><A href={link}><ObjectFinder collection="matters" _id={d.matter} /></A></td>
                                                <td>
                                                    <A href={link}>
                                                        {d.assigned_to && d.assigned_to.length ? d.assigned_to.map((e, i) => (
                                                            <span key={i}>{i === 0 ? '' : ', '}<ObjectFinder collection="users" _id={e} /></span>
                                                        )) : ''}    
                                                    </A>
                                                </td>
                                                <td>{moment.unix(d.due_date).format('MM/DD/YYYY h:mm A')}</td>
                                                <td className="text-right">
                                                    <A href={link} className="btn btn-sm btn-outline-info"><i className="fas fa-link mr-2" /> Open</A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No results found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : ''}
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const Box = ({ DOMAINS, selected_division, title, value, color, collection, filter }) => {

    let style = { background: 'none' }
    if(color) style.borderBottom = 'solid 1px white'

    return (
        <Col md={3}>
            <ModalToggler component={ModalItems} title={title} collection={collection} filter={filter} DOMAINS={DOMAINS} selected_division={selected_division}>
                <Card className={`cursor-pointer bg-gradient-${color}`}>
                    <CardHeader style={style}>
                        <CardTitle className={`mb-0 text-center ${color ? 'text-white' : ''}`}>{title}</CardTitle>
                    </CardHeader>
        
                    <CardBody className='text-center py-3'>
        
                        <p className={`mb-0 display-2 ${color ? 'text-white' : ''}`}>{value}</p>
                    </CardBody>
                </Card>
            </ModalToggler>

        </Col>
    )
}

const DashboardOverview = ({ DOMAINS, selected_division, data }) => (
    <Row>
        {data.map((d, i) => (
            <Box 
                key={i}
                selected_division={selected_division}
                DOMAINS={DOMAINS}
                title={d.title}
                value={d.value}
                color={d.color}
                collection={d.collection}
                filter={d.filter}
            />
        ))}       
    </Row>
)


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
        DOMAINS: state.load.DOMAINS,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);